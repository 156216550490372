import * as React from "react"
import { Link } from 'gatsby';
import { useEffect } from "react";
import Layout from "../templates/layout-standard"
import Seo from "../components/seo"
import SideBar from '../components/sidebar';
import SpeakWithAdvisor from '../components/ui/SpeakWithAdvisor';

const ContactUs = ({ location }) => {

	useEffect (() => {
			const dynFormScript = document.createElement("script");
			dynFormScript.src = "https://mktdplp102cdn.azureedge.net/public/latest/js/form-loader.js?v=1.74.2001.0";
			dynFormScript.async = false;
			document.body.appendChild(dynFormScript);

			const dynTrackScript = document.createElement("script");
			dynTrackScript.src = "https://mktdplp102cdn.azureedge.net/public/latest/js/ws-tracking.js?v=1.76.2003.0";
			dynTrackScript.async = false;
			document.body.appendChild(dynTrackScript);
	},[]);

	return (
		<Layout location={location} heading1="Contact Us" heading2="We&rsquo;re here to help.">
			<Seo title="Contact Us" meta={[{description: 'Connect with UVM Health Advantage in a way that works best for you and your schedule.'}]} bodyclass="contact-us subpage" />
			
			<div className="constrained">
				<div className="content-constrained">
					<div className="flexwrap_subpage">
						<main>
							<p>Connect with UVM Health Advantage in a way that works best for you and your schedule &ndash; to help you understand your options, find the right plan and make sure your transition to your new plan goes smoothly with no disruption to your care.</p>

							<h3>Call us.</h3>
							<p>Talk with a UVM Health Advantage Plan Guide.</p>
							<p><span className="phone_desktop"><b>1-833-368-4592</b></span><a className="phone_mobile" href="tel:18333684592"><b>1-833-368-4592</b></a> (TTY 711)<br />October 1-March 31, seven days a week, 8&nbsp;a.m.-8&nbsp;p.m. Eastern Time.<br />
								April 1-September 30, Monday-Friday, 8&nbsp;a.m.-8&nbsp;p.m.</p>
							{/* <b>1-833-368-4592</b> (TTY 711)<br />Seven days a week, 8&nbsp;a.m.-8&nbsp;p.m. Eastern Time<br />
							April 1-September 30, Monday-Friday, 8&nbsp;a.m.-8&nbsp;p.m. </p> */}

							<h3>Visit us.</h3>
							<p>MVP Health Care<br />
							62 Merchant&rsquo;s Row<br />
							Williston, VT</p>
							<p>Monday-Friday 9:00 a.m. &ndash; 5:00 p.m.</p>
							<p><a href="https://outlook.office365.com/owa/calendar/UVMHealthAdvantagePlanConsultationScheduling@mvphealthcare.onmicrosoft.com/bookings/" class="external">Schedule an appointment to meet with us</a> at our Williston office.</p>

							<h3>Meet with us.</h3>
							<p><Link to="/meet-with-us/">Schedule a personal consultation</Link> to meet with a UVM Health Advantage Plan Guide or attend a free Medicare Seminar.</p>
							
							<SpeakWithAdvisor />

						</main>

						<SideBar location={location} />
					</div>
				</div>
			</div>
		</Layout>
	)
}

export default ContactUs
